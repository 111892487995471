    /**
   * Private module, a utility, required internally by 'http-auth-interceptor'.
   */
  angular.module('sq.common.httpInterceptorBuffer', [])

  .factory('httpBuffer', function($injector, $q, $log) {
    /** Holds all the requests, so they can be re-requested in future. */
    var buffer = {};

    /** Service initialized later because of circular dependency problem. */
    var $http;

    function retryHttpRequest(config, deferred) {
      function successCallback(response) {
        return deferred.resolve(response);
      }
      function errorCallback(response) {
        deferred.reject(response);
      }
      $http = $http || $injector.get('$http');
      $http(config).then(successCallback, errorCallback);
    }

    return {
      /**
       * Appends HTTP request configuration object with deferred response attached to buffer.
       */
      defer: function(config) {
        var sloppyUnique = JSON.stringify(config);
        if (buffer[sloppyUnique] ) {
          return buffer[sloppyUnique].deferred;
        }
        var deferred = $q.defer();
        buffer[sloppyUnique] = {
          config: config,
          deferred: deferred,
        };
        return buffer[sloppyUnique].deferred;
      },

      /**
       * Abandon or reject (if reason provided) all the buffered requests.
       */
      rejectAll: function(reason) {
        for (var key in buffer) {
          if (!buffer.hasOwnProperty(key) ) {continue; }
          buffer[key].deferred.reject(reason);
        }
        
        buffer = {};
      },

      /**
       * Retries all the buffered requests clears the buffer.
       */
      retryAll: function(updater) {
        for (var key in buffer) {
          if (!buffer.hasOwnProperty(key) ) {continue; }
          retryHttpRequest(updater(buffer[key].config), buffer[key].deferred);
        }
        buffer = {};
      },
    };
  });
    // this doesn't seem to work well, when retryAll is called this.buffer is empty
    // event though items were stored using this.defer
   //  /** Holds all the requests, so they can be re-requested in future. */
   //  var $httpInst;
   //  var buffers = {};
   //  function Buffer() {
   //    this.buffer = {};
   //  }

   //  Buffer.getInstance = function getInstance(type) {
   //    if (!buffers[type] ) {
   //      buffers[type] = new Buffer();
   //      return buffers[type];
   //    }
   //    return buffers[type];
   //  };

   //  /** Service initialized later because of circular dependency problem. */
   //  Buffer.prototype.retryHttpRequest = function retryHttpRequest(config, deferred) {
   //    function successCallback(response) {
   //      return deferred.resolve(response);
   //    }
   //    function errorCallback(response) {
   //      deferred.reject(response);
   //    }
   //    $httpInst = $httpInst || $injector.get('$http');
   //    $httpInst(config).then(successCallback, errorCallback);
   //  };

   //  /**
   //  * Appends HTTP request configuration object with deferred response attached to buffer.
   // */
   //  Buffer.prototype.defer = function defer(config) {
   //    var sloppyUnique = JSON.stringify(config);
   //    if (this.buffer[sloppyUnique] ) {
   //      return this.buffer[sloppyUnique].deferred;
   //    }
   //    var deferred = $q.defer();
   //    this.buffer[sloppyUnique] = {
   //      config: config,
   //      deferred: deferred,
   //    };
   //    return this.buffer[sloppyUnique].deferred;
   //  };

   //    /**
   //     * Abandon or reject (if reason provided) all the buffered requests.
   //     */
   //  Buffer.prototype.rejectAll = function rejectAll(reason) {
   //    var self = this;
   //    if (reason) {
   //      for (var key in this.buffer) {
   //        if (!self.buffer.hasOwnProperty(key) ) {continue; }
   //        self.buffer[key].deferred.reject(reason);
   //      }
   //    }
   //    this.buffer = {};
   //  };

   //    /**
   //     * Retries all the buffered requests clears the buffer.
   //     */
   //  Buffer.prototype.retryAll = function retryAll(updater) {
   //    $log.debug('retryAll', angular.copy(this) );
   //    var self = this;
   //    for (var key in this.buffer) {
   //      if (!self.buffer.hasOwnProperty(key) ) {continue; }
   //      this.retryHttpRequest(
   //        updater(self.buffer[key].config), self.buffer[key].deferred
   //      );
   //    }
   //    this.buffer = {};
   //  };

   //  return Buffer;
  // });